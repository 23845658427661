import React from "react";
import {BooleanField, DateField, Show, SimpleShowLayout, TextField, UrlField} from 'react-admin';

const OurSurveysProjectShow = (props: any) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="ext_project_id" />
            <UrlField source="entry_link" />
            <DateField source="created_at" showTime={true} />
            <DateField source="updated_at" showTime={true} />
            <TextField source="project_type" />
            <TextField source="in_field_conversion_rate" />
            <TextField source="country" />
            <TextField source="cpi" />
            <BooleanField source="delay_crediting" />
            <TextField source="in_field_loi" />
            <BooleanField source="match_to_qualify" />
            <TextField source="remaining_completes" />
            <BooleanField source="tentative_payout" />
            <TextField component="textarea" source="raw_survey_def" />
            <TextField source="qualification_query" />
            <TextField source="project_status" />
            <TextField source="qualification_query" />
            <TextField source="order_loi" />
            <TextField source="order_ir" />
            <BooleanField source="qualifications_fully_mapped" />
            <TextField source="panel_project_id" />
            <TextField source="entry_link_secret" />
            <TextField source="exit_link_secret" />
            <TextField source="cpi_currency" />
            <TextField source="demand_source" />

        </SimpleShowLayout>
    </Show>
);

export default OurSurveysProjectShow
