import React from 'react'
import {
    Edit,
    SimpleForm,
    TextInput,
    TextField,
    BooleanInput, DateField

} from 'react-admin'

export const CommunityWorkersEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput disabled source="name" />
            <BooleanInput source="is_active" />
            <TextInput disabled source='interval_secs' />
            <DateField source="last_success" showTime={true} />
            <DateField source="last_failure" showTime={true} />
            <TextField source='last_error_message' />
            <TextField source="args" />
            <DateField source="created_at" showTime={true} />
            <DateField source="updated_at" showTime={true} />
        </SimpleForm>
    </Edit>
);
