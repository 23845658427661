import * as React from 'react';
import { Menu, MenuItemLink } from 'react-admin';
import { ReactComponent as OurSurveysIcon } from './oursurveys.svg';
import { ReactComponent as ProfilersIcon } from './profilers.svg';
import { ReactComponent as WorkersIcon } from './workers.svg';
import { ReactComponent as LeadsIcon } from './manager.svg';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useEffect} from "react";

export default function CustomMenu(props: any) {
    const [osExpanded, setOsExpanded] = React.useState(false);
    const [commExpanded, setCommExpanded] = React.useState(false);

    useEffect(() => {
        if(window.location.href.indexOf('oursurveys') > -1 && window.location.href.indexOf('sub_panels') === -1){
            setOsExpanded(true);
        }
        if(window.location.href.indexOf('community') > -1 || window.location.href.indexOf('sub_panels') > -1){
            setCommExpanded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Menu {...props}>
            <Accordion expanded={osExpanded}  onChange={()=>{setOsExpanded(!osExpanded)}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <OurSurveysIcon />
                    <Typography>Oursurveys</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* @ts-ignore*/}
                    <MenuItemLink to="/oursurveys_profilers" primaryText="Profilers" leftIcon={<OurSurveysIcon />}/>
                    {/* @ts-ignore*/}
                    <MenuItemLink to="/oursurveys_projects" primaryText="Projects" leftIcon={<ProfilersIcon />}/>
                    {/* @ts-ignore*/}
                    <MenuItemLink to="/oursurveys_workers" primaryText="Workers" leftIcon={<WorkersIcon />}/>
                    {/* @ts-ignore*/}
                    <MenuItemLink to="/oursurveys_sub_panel_demand_sources" primaryText="Sub Panels"
                                  leftIcon={<OurSurveysIcon />}/>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={commExpanded}  onChange={()=>{setCommExpanded(!commExpanded)}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <OurSurveysIcon />
                    <Typography>Community</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* @ts-ignore*/}
                    <MenuItemLink to="/community_leads" primaryText="Leads" leftIcon={<LeadsIcon />}/>
                    {/* @ts-ignore*/}
                    <MenuItemLink to="/community_translation_bundles" primaryText="Translation Bundles"
                                  leftIcon={<LeadsIcon />}/>
                    {/* @ts-ignore*/}
                    <MenuItemLink to="/community_geopc_places_view" primaryText="Places"
                                  leftIcon={<LeadsIcon />}/>
                    {/* @ts-ignore*/}
                    <MenuItemLink to="/community_geopc_regions_view" primaryText="Regions"
                                  leftIcon={<LeadsIcon />}/>
                    <MenuItemLink to="/community_languages" primaryText="Languages"
                                  leftIcon={<LeadsIcon />}/>
                    <MenuItemLink to="/community_countries" primaryText="Countries"
                                  leftIcon={<LeadsIcon />}/>
                    <MenuItemLink to="/community_workers" primaryText="Workers"
                                  leftIcon={<LeadsIcon />}/>
                    <MenuItemLink to="/community_panel_config_keys" primaryText="Panel Config Keys"
                                  leftIcon={<LeadsIcon />}/>
                    <MenuItemLink to="/community_sub_panels" primaryText="Panel settings"
                                  leftIcon={<LeadsIcon />}/>
                </AccordionDetails>
            </Accordion>


        </Menu>
    )

}

// @ts-ignore
// const CustomMenu = (props: any) => (
//     // @ts-ignore
//
// );
//
// export default CustomMenu;
