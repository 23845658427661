import { Layout } from 'react-admin';
import CustomAppBar from './customAppBar';
import CustomMenu from './customMenu';

const CustomLayout = (props: any) => <Layout
    {...props}
    appBar={CustomAppBar}
    menu={CustomMenu}
/>;

export default CustomLayout;
