import React from "react";
import {BooleanField, DateField, Show, SimpleShowLayout, TextField} from 'react-admin';

const CommunityWorkersShow = (props: any) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source='id' />
            <TextField source='name' />
            <BooleanField source='is_active' />
            <TextField source='interval_secs' />
            <DateField source="last_success" showTime={true} />
            <DateField source="last_failure" showTime={true} />
            <TextField source='last_error_message' />
            <TextField source="args" />
            <DateField source="created_at" showTime={true} />
            <DateField source="updated_at" showTime={true} />
        </SimpleShowLayout>
    </Show>
);

export default CommunityWorkersShow
