import React, {useState, useEffect } from "react";
import {
    Show, SimpleShowLayout, TextField, useDataProvider, useNotify
} from 'react-admin';
import Button from '@mui/material/Button';
import ReactSelect from 'react-select'
import find from 'lodash/find';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@mui/material/Paper';



const ProfilersEdit = (props: any) => {

    const useStyles = makeStyles((theme) => ({
        table: {
            minWidth: 650,
        },
        form: {
            minWidth: 600
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        label: {
            marginTop: 50
        },
        button: {
            marginTop: 30
        },
        dvmapping: {
            fontSize: 13,
            marginTop: 20,
            marginBottom: 10
        },
        select:{
            minWidth: 250
        }
    }));
    // useStyles();
    const classes = useStyles();

    const dataProvider = useDataProvider();
    const [dataVariables, setDataVariables] = useState<any[]>([]);
    const [record, setRecord] = useState<any>(null);
    const [values, setValues] = useState<any[]>([]);


    const notify = useNotify();

    useEffect(() => {

        dataProvider
            .getOne("oursurveys_profilers", {
                id: props.id
            })
            .then(({ data }) => {
                setRecord(data);
            })
            .catch((error) => {

            });

        dataProvider
            .getList("oursurveys_data_variables", {
                pagination: { page: 1, perPage: 10000 },
                sort: { field: "id", order: "ASC" },
                filter: {},
            })
            .then(({ data }) => {
                setDataVariables(data);
            })
            .catch((error) => {

            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        const tempValues: any[] = [];

        if (record && record.profiler_variable_question_maps.length > 0) {

            const selectedAnswerObj: any = {};
            record.profiler_answersIds.forEach((answerId: number) => {
                selectedAnswerObj[answerId] = [];
            })

            record.profiler_variable_question_maps.forEach((mapping: any) => {
                tempValues.push({
                    ...mapping,
                    selectedAnswers: selectedAnswerObj
                });
            })

            record.profiler_answers.forEach((answer: any) => {
                answer.profiler_variable_answer_maps.forEach((answerMap: any) => {
                    const dataMapRecord = find(tempValues, {"data_variable.id": answerMap.data_variable_option.data_variable_id});
                    //todo check this!
                    if(dataMapRecord){
                        dataMapRecord.selectedAnswers[answer.id].push(answerMap.data_variable_option.id);
                    }
                })
            })

        }
        setValues(tempValues);


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record, dataVariables]);


    const handleChangeDataVariable = (valuesIndex: number) => (value: any) => {
        const newValues = JSON.parse(JSON.stringify(values));

        dataProvider
            .getOne("oursurveys_data_variables", {
                id: value.value
            })
            .then(({ data }) => {
                newValues[valuesIndex].data_variable = data;
                newValues[valuesIndex]['data_variable.id'] = value.value;
                record.profiler_answersIds.forEach((answerId: number) => {
                    newValues[valuesIndex].selectedAnswers[answerId] = [];
                })

                setValues(newValues);
            })
            .catch((error) => {

            });
    }

    const handleAnswerMappingChange = (valuesIndex: number, id: number) => ( event: any ) => {
        const newValues = JSON.parse(JSON.stringify(values));
        newValues[valuesIndex].selectedAnswers[id] = event.target.value;
        setValues(newValues);
        // const newProfilersAnswers = JSON.parse(JSON.stringify(profilerAnswers));
        // const modifiedAnswer = find(newProfilersAnswers, { id });
        // modifiedAnswer.data_variable_option_id = event.target.value;
        //
        // setProfilersAnswers(newProfilersAnswers);
    }

    const handleAddDataMapping = () => {
        const newValues = JSON.parse(JSON.stringify(values));

        const selectedAnswerObj: any = {};
        record.profiler_answersIds.forEach((answerId: number) => {
            selectedAnswerObj[answerId] = [];
        })
        newValues.push({
            data_variable: null,
            "data_bariable.id": null,
            selectedAnswers: selectedAnswerObj
        })
        setValues(newValues);
    }

    const handleDeleteMapping = (valuesIndex: number) => () => {
        const newValues = JSON.parse(JSON.stringify(values));
        newValues.splice(valuesIndex, 1);
        setValues(newValues)
    }

    const handleSave = async () => {

        await dataProvider
            .getList("oursurveys_profiler_variable_question_map", {
                pagination: { page: 1, perPage: 10000 },
                sort: { field: "id", order: "ASC" },
                filter: { profiler_id: record.id },
            })
            .then(({ data }) => {
                const ids = data.flatMap(mapping => mapping.id)
                dataProvider.deleteMany("oursurveys_profiler_variable_question_map",  {
                    ids: ids
                })
                    .then(({ data }) => {

                    })
                    .catch((error) => {

                    });

            })
            .catch((error) => {

            });

        await dataProvider
            .getList("oursurveys_profiler_variable_answer_map", {
                pagination: { page: 1, perPage: 10000 },
                sort: { field: "id", order: "ASC" },
                filter: { profiler_answer_id: record.profiler_answers.flatMap((answer:any) => answer.id) },
            })
            .then(({ data }) => {
                const ids = data.flatMap(mapping => mapping.id)

                dataProvider.deleteMany("oursurveys_profiler_variable_answer_map",  {
                    ids: ids
                })
                    .then(({ data }) => {

                    })
                    .catch((error) => {

                    });

            })
            .catch((error) => {

            });

        const questionMappingsCreate:number[] = [];
        values.forEach((val: any) => {
            if(val.data_variable){
                questionMappingsCreate.push(val.data_variable.id);
            }
        })


        await Promise.all(questionMappingsCreate.map(async (value) => {
            await dataProvider.create('oursurveys_profiler_variable_question_map', {
                data: {
                    profiler_id: record.id,
                    data_variable_id: value
                },
            })
                .then(({data}) => {

                })
                .catch((error) => {

                });
        }));

        const answerMappingsCreate:[number, number][] = [];
        values.forEach((val: any) => {
            for (const answerId in val.selectedAnswers) {
                val.selectedAnswers[answerId].forEach((ans_id: number) => {
                    answerMappingsCreate.push([parseInt(answerId), ans_id]);
                })
            }
        })
        await Promise.all(answerMappingsCreate.map(async (value) => {
            await dataProvider.create('oursurveys_profiler_variable_answer_map', {
                data: {
                    profiler_answer_id: value[0],
                    data_variable_option_id: value[1]
                },
            })
                .then(({data}) => {

                })
                .catch((error) => {

                });
        }));


        // profilerAnswers.forEach((answ, index) => {
        //     if(answ.data_variable_option_id !== initialProfilerAnswers[index].data_variable_option_id){
        //         // if(!isEqual(answ, initialProfilerAnswers[index])){
        //         dataProvider.update("oursurveys_profiler_answers",  {
        //             id: answ.id,
        //             data: {
        //                 data_variable_option_id: answ.data_variable_option_id
        //             },
        //             previousData: initialProfilerAnswers[index]
        //         })
        //             .then(({ data }) => {
        //                 const newInitialProfilerAnswers = JSON.parse(JSON.stringify(initialProfilerAnswers));
        //                 newInitialProfilerAnswers[index] = data;
        //                 setInitialProfilerAnswers(newInitialProfilerAnswers);
        //             })
        //             .catch((error) => {
        //
        //             });
        //     }
        // })
        //
        // if( dataVariable && record.data_variable_id !== dataVariable.value){
        //     dataProvider.update("oursurveys_profilers",  {
        //         id: record.id,
        //         data: {
        //             data_variable_id: dataVariable?.value || null
        //         },
        //         previousData: record
        //     })
        //         .then(({ data }) => {
        //             setRecord(data);
        //
        //         })
        //         .catch((error) => {
        //
        //         });
        // }

        notify(`Mappings updated`, { type: 'success' });
    }

    const dataVariableSelect = dataVariables.map((dv) => ({
        value: dv.id,
        label: dv.name,
    }));

    return (
        <Show {...props}>
                <SimpleShowLayout>
                    <TextField source="id"  />
                    <TextField source='text' />

                    {values.map((value, index)=> (
                        <Paper elevation={3} style={{padding: 20, margin: '30px 0px 30px 0px'}}>
                            <Grid container justifyContent="flex-end">
                                <Button variant="outlined" onClick={handleDeleteMapping(index)}  color="error" >
                                    Delete bundle
                                </Button>
                            </Grid>
                            <h3>Data variable mapping #{index+1}</h3>
                            <InputLabel className={classes.dvmapping}>Data variable mapping</InputLabel>
                            <ReactSelect
                                options={dataVariableSelect}
                                value={value.data_variable ? {
                                    value: value.data_variable.id,
                                    label: value.data_variable.name
                                }: null}
                                // onChange={setDataVariable}
                                onChange={handleChangeDataVariable(index)}
                            />

                            <h4 className={classes.label}>Answer mappings</h4>
                            <Grid container spacing={1} style={{ width: "100%" }}>
                                <TableContainer>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ID</TableCell>
                                                <TableCell>External ID</TableCell>
                                                <TableCell>Text</TableCell>
                                                <TableCell>Mapping</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {record.profiler_answers.map((row: any) => (
                                                <TableRow key={row.id}>
                                                    <TableCell component="th" scope="row">
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell>{row.external_id}</TableCell>
                                                    <TableCell>{row.text}</TableCell>
                                                    <TableCell >
                                                        <FormControl className={classes.formControl}>
                                                            <Select
                                                                value={value.selectedAnswers[row.id]}
                                                                onChange={handleAnswerMappingChange(index, row.id)}
                                                                className={classes.select}
                                                                multiple
                                                                style={{width: 350}}
                                                            >
                                                                {value.data_variable &&
                                                                    value.data_variable.data_variable_options.map((dvo:any) => (
                                                                    <MenuItem value={dvo.id}>{dvo.name}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>

                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Paper>
                    ))}


                    <Button variant="outlined" color="primary" onClick={handleAddDataMapping}>
                        Add new data variable mapping
                    </Button>

                    <Button variant="contained"
                            color="success" className={classes.button} onClick={handleSave}>Save mappings</Button>

                </SimpleShowLayout>

            {/*}*/}
        </Show>
    )
};

export default ProfilersEdit
