import React from 'react'
import {
    List,
    Datagrid,
    TextField
} from 'react-admin'

const CountriesList = (props: any) => {
    return (
        <List bulkActionButtons={false} perPage={50} {...props} >
            <Datagrid>
                <TextField source='id' />
                <TextField source='code' />
                <TextField source='iso_code' />
                <TextField source='area_code' />
                <TextField source='currency_code' />
            </Datagrid>
        </List>
    )
}

export default CountriesList
