import React, {useState, useEffect} from "react";
import {
    Show, SimpleShowLayout, TextField as RaTextField, useDataProvider, useNotify
} from 'react-admin';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Select  from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import find from "lodash/find";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const SubPanelsEdit = (props: any) => {


    const dataProvider = useDataProvider();
    const notify = useNotify();

    const [subPanel, setSubPanel] = useState<any>(null);
    const [keys, setKeys] = useState<any>([]);
    const [values, setValues] = useState<any>([]);

    const [selectedTab, setSelectedTab] = React.useState('');

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        dataProvider
            .getOne("community_sub_panels", {
                id: props.id
            })
            .then(({ data }) => {
                setSubPanel(data);
            })
            .catch((error) => {

            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchValues = () => {
        dataProvider
            .getList("community_panel_config_values", {
                pagination: { page: 1, perPage: 10000 },
                sort: { field: "id", order: "ASC" },
                filter: {panel_id: subPanel.id},
                // filter: {},

            })
            .then(({ data }) => {
                setValues(data);
            })
            .catch((error) => {

            });
    }

    useEffect(() => {
        if(subPanel){
            setSelectedTab(subPanel.sub_panel_locales_info[0].id);
            fetchValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subPanel]);

    useEffect(() => {
        if(subPanel){
            dataProvider
                .getList("community_panel_config_keys", {
                    pagination: { page: 1, perPage: 10000 },
                    sort: { field: "id", order: "ASC" },
                    filter: {},
                })
                .then(({ data }) => {
                    const modData = JSON.parse(JSON.stringify(data));
                    modData.forEach((row: any) => {
                        row.value = {};
                        subPanel.sub_panel_locales_info.forEach((locale: any) => {
                            const value = find(values, {panel_config_key_id: row.id, locale_id: locale.id})
                            if (value) {
                                row.value[locale.id] = value;
                            }else{
                                row.value[locale.id] = {
                                    panel_config_key_id: row.id,
                                    panel_id: subPanel.id,
                                    value: undefined,
                                    locale_id: locale.id
                                }
                            }
                        });
                    });
                    setKeys(modData);
                })
                .catch((error) => {

                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const handleChange = (keyId: number, localeId: number) => (event: any) => {
        const modKeys = JSON.parse(JSON.stringify(keys));
        const modKey = find(modKeys, {id: keyId});
        modKey.value[localeId].value = event.target.value;

        setKeys(modKeys);
    }

    const handleSave = (localeId: number) => async () => {
        const toCreate:any[] = [];
        const toUpdate:any[] = [];

        keys.forEach((key: any) => {
            const existingValue = find(values, {panel_config_key_id: key.id, locale_id: localeId});
            if (existingValue) {
                if(existingValue.value !== key.value[localeId].value){
                    toUpdate.push({
                        updatedValue: key.value[localeId],
                        existingValue: existingValue
                    })
                }
            } else {
                if (key.value[localeId].value) {
                    toCreate.push(key.value[localeId]);
                }
            }
        });

        await Promise.all(toUpdate.map(async (value: any) => {
            await dataProvider.update("community_panel_config_values",  {
                id: value.existingValue.id,
                data: {
                    value: value.updatedValue.value
                },
                previousData: value.existingValue
            })
                .then(({ data }) => {

                })
                .catch((error) => {

                });

        }));

        await Promise.all(toCreate.map(async (value: any) => {
            await dataProvider.create('community_panel_config_values', {
                data: value,
            })
                .then(({data}) => {

                })
                .catch((error) => {

                });
        }));

        notify(`Panel settings updated successfully`, { type: 'success' });
        fetchValues();
    }

    const renderTextField = (key: any, locale:any) => {
        return (
                <TextField id="standard-basic" variant="standard" style={{minWidth: 500}}
                    value = {key.value[locale.id].value}
                    onChange={handleChange(key.id, locale.id)}
                />
        )
    }

    const renderBoolean = (key: any, locale:any) => {
        return (
            <>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value = {key.value[locale.id].value}
                    row
                    onChange={handleChange(key.id, locale.id)}
                >
                    <FormControlLabel value="true" control={<Radio />} label="True" />
                    <FormControlLabel value="false" control={<Radio />} label="False" />
                </RadioGroup>
            </>
        )
    }

    const renderSelect = (key: any, locale:any) => {
        return (
            <>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{minWidth: 500}}
                    value = {key.value[locale.id].value}
                    onChange={handleChange(key.id, locale.id)}
                >
                    {
                        key.panel_config_key_values.map((values: any) => (
                            <MenuItem value={values.value}>{values.label}</MenuItem>
                        ))
                    }

                </Select>
            </>
        )
    }

    return (
        <Show {...props}>
            <SimpleShowLayout>
                {/*<Box style={{marginBottom: 50}}>*/}
                    <RaTextField source="id"  />
                    <RaTextField source='panel_id' />
                    <RaTextField source='panel_uuid' />
                    <RaTextField source='panel_name' />
                {/*</Box>*/}

                <h3 style={{marginTop: 50}}>Panel Settings</h3>

                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={selectedTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="locale tabs">
                                {
                                    subPanel && subPanel.sub_panel_locales_info.map((locale: any) => (
                                        <Tab label={locale.code} value={locale.id} />
                                    ))
                                }
                            </TabList>
                        </Box>
                        {
                            subPanel && subPanel.sub_panel_locales_info.map((locale: any) => (
                                <TabPanel value={locale.id}>
                                    <Grid>
                                        {
                                            keys.map((key: any) => (
                                                <Box style={{marginBottom: 50}}>
                                                    <Typography style={{marginBottom: 10, fontSize: '13px'}}>
                                                        {key.label}
                                                    </Typography>
                                                    { (key.type === 'text' || key.type === 'list' ) &&
                                                    renderTextField(key, locale)}
                                                    { key.type === 'bool' && renderBoolean(key, locale)}
                                                    { key.type === 'select' && renderSelect(key, locale)}
                                                </Box>

                                            ))
                                        }
                                    </Grid>

                                    <Button variant="outlined" color="success"
                                            onClick={handleSave(locale.id)}  size="large" >
                                        Save panel settings
                                    </Button>
                                </TabPanel>
                            ))
                        }

                    </TabContext>
                </Box>




            </SimpleShowLayout>

        </Show>
    )
};

export default SubPanelsEdit
