import React, {useEffect, useRef, useState} from 'react'
import {
    useDataProvider, useNotify
} from 'react-admin'
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Card from "@mui/material/Card";
import {useHistory} from "react-router-dom";
import { PanelConfigKey, Value } from "./interfaces/panel-config-key";

const PanelConfigKeysEdit = (props: any) => {

    const notify = useNotify();
    const dataProvider = useDataProvider();
    const history = useHistory();

    const [record, setRecord] = useState<PanelConfigKey | null>(null);
    const initialRecord = useRef<PanelConfigKey | null>(null);

    useEffect(() => {
        dataProvider
            .getOne("community_panel_config_keys", {
                id: props.id
            })
            .then(({ data }) => {
                setRecord(data as PanelConfigKey);
                initialRecord.current = data as PanelConfigKey;
            })
            .catch((error) => {

            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [newValue, setNewValue] = useState<Value>({
        label: '',
        value: ''
    });

    const [valueDialogOpen, setValueDialogOpen] = useState(false);

    const handleChange = (key: 'key'|'label'|'type') => (event: any) => {
        const modifiedKey = JSON.parse(JSON.stringify(record));
        modifiedKey![key] = event.target.value;
        setRecord(modifiedKey);
    };

    const handeChangeValue = (key: string) => (event: any) => {
        const modifiedValue = JSON.parse(JSON.stringify(newValue));
        modifiedValue[key] = event.target.value;
        setNewValue(modifiedValue);
    };

    const handleDelete = (value: Value) => () => {
        const modifiedKey = JSON.parse(JSON.stringify(record));
        modifiedKey.panel_config_key_values =
            modifiedKey.panel_config_key_values.filter((key:Value) => JSON.stringify(key) !== JSON.stringify(value));
        setRecord(modifiedKey);
    }


    const handleSave =  () => {
        if (record!.key === '' || record!.label === '') {
            notify(`Please fill all values`, { type: 'error' });
            return;
        }
        if (record!.type === 'select' && record!.panel_config_key_values.length < 2) {
            notify(`Please provide at least two selectable values`, { type: 'error' });
            return;
        }
        dataProvider.update('community_panel_config_keys', {
            id: props.id,
            data: {
                key: record!.key,
                label: record!.label,
                type: record!.type
            },
            previousData: initialRecord as any
        })
            .then(({data}) => {

                if (initialRecord.current!.type === 'select') {
                    dataProvider
                        .getList("community_panel_config_key_values", {
                            pagination: { page: 1, perPage: 10000 },
                            sort: { field: "id", order: "ASC" },
                            filter: { panel_config_key_id: props.id },
                        }).then(({ data }) => {
                            const ids = data.flatMap(value => value.id)

                            dataProvider.deleteMany("community_panel_config_key_values",  {
                            ids: ids
                        })
                            .then(({ data }) => {
                            })
                            .catch((error) => {

                            });

                    }).catch((error) => {

                    });
                }

                if (data.type === 'select' ) {
                    const clonedData = JSON.parse(JSON.stringify(data));
                    const createValues = async () => {
                        await Promise.all(record!.panel_config_key_values.map(async (value) => {
                            await dataProvider.create('community_panel_config_key_values', {
                                data: {
                                    panel_config_key_id: clonedData.id,
                                    label: value.label,
                                    value: value.value
                                },
                            })
                                .then(({data}) => {

                                })
                                .catch((error) => {

                                });
                        }));
                    }
                    createValues();
                }

                notify(`Key updated successfully`, { type: 'success' });
                history.push("/community_panel_config_keys");

            })
            .catch((error) => {

            });

    }

    const handleCloseDialog = () => {
        setNewValue({
            label: '',
            value: ''
        })
        setValueDialogOpen(false);
    };

    const saveNewValue = () => {
        const modifiedKey = JSON.parse(JSON.stringify(record));
        if(newValue.label === '' || newValue.value === ''){
            notify(`Please fill both fields before saving`, { type: 'error' });
            return;
        }
        modifiedKey.panel_config_key_values.push(newValue)
        setRecord(modifiedKey);
        handleCloseDialog();
    }

    return (
        <Card sx={{ padding: '30px' }}>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"

            >
                <TextField id="standard-basic" label="Key" variant="standard"
                           style={{ minWidth: 300, marginBottom: 30 }}
                           value={record?.key ?? ''}
                           onChange={handleChange('key')}

                />
                <TextField id="standard-basic" label="Label" variant="standard"
                           style={{ minWidth: 300, marginBottom: 30 }}
                           value={record?.label ?? ''}
                           onChange={handleChange('label')}
                />
                <ToggleButtonGroup
                    color="primary"
                    value={record?.type ?? ''}
                    style={{ marginTop: 20, marginBottom: 30 }}
                    exclusive
                    onChange={handleChange('type')}
                >
                    <ToggleButton value="text">Text</ToggleButton>
                    <ToggleButton value="bool">Boolean</ToggleButton>
                    <ToggleButton value="select">Select</ToggleButton>
                    <ToggleButton value="list">List</ToggleButton>
                </ToggleButtonGroup>

                {record && record.type === 'select' &&
                <>
                  <Grid
                    container
                    direction="row"
                  >
                      {record && record.panel_config_key_values.map((value: Value)=>(
                          <Chip label={`${value.value} - ${value.label}`} variant="outlined"
                                style={{ marginRight: 5}}
                                onClick={handleDelete(value)}
                                onDelete={handleDelete} />

                      ))}
                  </Grid>
                  <Button
                    onClick={()=>{setValueDialogOpen(true)}}
                    style = {{marginBottom: 30}}
                  >Add new selectable value</Button>
                </>
                }



                <Button variant="outlined" color="success"
                        size="large"
                        onClick={handleSave}
                >
                    Update key
                </Button>
            </Grid>

            <Dialog open={valueDialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Add new key</DialogTitle>
                <DialogContent>

                    <TextField id="standard-basic" label="Value" variant="standard"
                               style={{ minWidth: 300}}
                               value={newValue.value}
                               onChange={handeChangeValue('value')}

                    />
                    <TextField id="standard-basic" label="Label" variant="standard"
                               style={{ minWidth: 300}}
                               value={newValue.label}
                               onChange={handeChangeValue('label')}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} >Cancel</Button>
                    <Button onClick={saveNewValue}>Add keys</Button>
                </DialogActions>
            </Dialog>
        </Card>

    )
}

export default PanelConfigKeysEdit
