import React, { useState, useEffect } from "react";
import {fetchUtils, Admin, Resource } from "react-admin";
import authProvider from "./authProvider";
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
// @ts-ignore
import buildHasuraProvider from 'ra-data-hasura';
import { onError } from "@apollo/client/link/error";
import simpleRestProvider from "ra-data-simple-rest";
import CustomLayout from './customLayout';

import { setContext } from '@apollo/client/link/context';
import OurSurveysProjectsList from "./components/ourSurveysProjects/list";
import OurSurveysProjectsShow from "./components/ourSurveysProjects/show";
import OurSurveysWorkersList from "./components/ourSurveysWorkers/list";
import OurSurveysWorkersShow from "./components/ourSurveysWorkers/show";
import CommunityLeadsShow from "./components/community/leads/show";
import CommunityLeadsList from "./components/community/leads/list";
import TranslationBundlesList from "./components/community/translationBundles/list";
import TranslationBundlesCreate from "./components/community/translationBundles/create";
import TranslationBundlesEdit from "./components/community/translationBundles/edit";
import ProfilersList from "./components/profilers/list";
import ProfilersEdit from "./components/profilers/edit";
import GeopcPlacesList from "./components/community/geopcPlaces/list";
import GeopcPlacesShow from "./components/community/geopcPlaces/show";
import GeopcRegionsList from "./components/community/geopcRegions/list";
import GeopcRegionsShow from "./components/community/geopcRegions/show";
import LanguagesList from "./components/community/languages/list";
import CountriesList from "./components/community/countries/list";
import CommunityWorkersList from "./components/community/workers/list";
import CommunityWorkersShow from "./components/community/workers/show";
import {CommunityWorkersEdit} from "./components/community/workers/edit";
import SubPanelDemandSourcesList from "./components/oursurveys/subPanelDemandSources/list";
import SubPanelDemandSourcesEdit from "./components/oursurveys/subPanelDemandSources/edit";
import CommunityPanelConfigKeysList from "./components/community/panelConfigKeys/list";
import CommunityPanelConfigKeysCreate from "./components/community/panelConfigKeys/create";
import CommunityPanelConfigKeysEdit from "./components/community/panelConfigKeys/edit";
import SubPanelsList from "./components/community/subPanels/list";
import SubPanelsEdit from "./components/community/subPanels/edit";
import customBuildFields from "./data/customBuildFields"

import ForgotPassword from './forgotPassword';
import LoginPage from './loginPage';
import { RouteWithoutLayout } from 'react-admin';
import ResetPassword from "./resetPassword";



const { REACT_APP_API_HOST, REACT_APP_API_AUTH_HOST } = process.env;


const App = () => {
    const [dataProvider, setDataProvider] = useState(null);

    const httpLink = createHttpLink({
        uri: REACT_APP_API_HOST,
    });

    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = localStorage.getItem('auth');

        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token && `Bearer ${token}`,
            }
        }
    });

    // Log any GraphQL errors or network error that occurred
    const errorLink = onError(({ graphQLErrors, networkError , operation, forward }) => {
        if (graphQLErrors){
            graphQLErrors.forEach(({ message, locations, path }) => {
                    console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                    );
                    localStorage.removeItem('auth');
                    window.location.reload();
            }

            );
        }
        if (networkError) console.log(`[Network error]: ${networkError}`);

    });

    const client = new ApolloClient({
        link: httpLink ? errorLink.concat(authLink.concat(httpLink)) : errorLink.concat(authLink),
        cache: new InMemoryCache(),
        defaultOptions: {
            mutate: { errorPolicy: "ignore" },
            query: { errorPolicy: "ignore" },
        },
    })

    useEffect(() => {
        if(localStorage.getItem('auth')){
            const buildTheDataProvider = async () => {

                /**
                 * Extracts just the fields from a GraphQL AST.
                 * @param {GraphQL AST} queryAst
                 */
                const dataProvider = await buildHasuraProvider({client}, { buildFields: customBuildFields });

                setDataProvider(() => dataProvider);
            };

            buildTheDataProvider();

        } else {
            const httpClient = (url: string, options: any = {}) => {
                if (!options.headers) {
                    options.headers = new Headers({ Accept: "application/json" });
                }
                const token = JSON.parse(localStorage.getItem("auth") as string);
                options.headers.set("Authorization", `Bearer ${token}`);
                return fetchUtils.fetchJson(url, options);
            };
            const dataProvider = simpleRestProvider(REACT_APP_API_AUTH_HOST!, httpClient);
            // @ts-ignore
            setDataProvider(() => dataProvider);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!dataProvider) {
        return <div>Loading </div>;
    }

    const customRoutes = [
        <RouteWithoutLayout exact path="/forgot-password" component={ForgotPassword}  />,
        <RouteWithoutLayout exact path="/reset" component={ResetPassword}  />
    ];

    return(
        <Admin authProvider={authProvider} dataProvider={dataProvider} layout={CustomLayout}
               customRoutes={customRoutes}
               loginPage={LoginPage}
        >
            <Resource
                name='oursurveys_sub_panel_demand_sources'
                options={{ label: 'Sub Panel Demand Sources' }}
                list={SubPanelDemandSourcesList}
                edit={SubPanelDemandSourcesEdit}
            />
            <Resource
                name='community_translation_bundles'
                options={{ label: 'Translation Bundles' }}
                list={TranslationBundlesList}
                show={TranslationBundlesEdit}
                create={TranslationBundlesCreate}
            />
            <Resource
                name='oursurveys_profilers'
                list={ProfilersList}
                show={ProfilersEdit}
            />
            <Resource
                name='oursurveys_projects'
                list={OurSurveysProjectsList}
                show={OurSurveysProjectsShow}
            />
            <Resource
                name='oursurveys_workers'
                list={OurSurveysWorkersList}
                show={OurSurveysWorkersShow}
            />
            <Resource
                name='community_leads'
                options={{ label: 'Leads' }}
                list={CommunityLeadsList}
                show={CommunityLeadsShow}
            />
            <Resource
                name='community_geopc_places_view'
                options={{ label: 'Places' }}
                list={GeopcPlacesList}
                show={GeopcPlacesShow}
            />
            <Resource
                name='community_geopc_regions_view'
                options={{ label: 'Regions' }}
                list={GeopcRegionsList}
                show={GeopcRegionsShow}
            />
            <Resource
                name='community_languages'
                options={{ label: 'Languages' }}
                list={LanguagesList}
            />
            <Resource
                name='community_countries'
                options={{ label: 'Countries' }}
                list={CountriesList}
            />
            <Resource
                name='oursurveys_profiler_answers'
            />
            <Resource
                name='oursurveys_data_variable_options'
            />
            <Resource
                name='community_workers'
                options={{ label: 'Workers' }}
                list={CommunityWorkersList}
                show={CommunityWorkersShow}
                edit={CommunityWorkersEdit}
            />

            <Resource
                name='community_sub_panels'
                list={SubPanelsList}
                edit={SubPanelsEdit}
            />

            <Resource
                name='community_panel_config_keys'
                options={{ label: 'Panel Settings Keys' }}
                list={CommunityPanelConfigKeysList}
                create={CommunityPanelConfigKeysCreate}
                edit={CommunityPanelConfigKeysEdit}
            />
            <Resource
                name='community_panel_config_key_values'
            />
            <Resource
                name='oursurveys_sub_panels'
            />

        </Admin>
    )
};

export default App;
