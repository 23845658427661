import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface ConfirmDialogProps {
    title: React.ReactNode;
    children: JSX.Element;
    open: boolean;
    onConfirm: () => void;
    onClose: () => void;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
    const { title, children, open, onConfirm, onClose } = props;
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => {
                        onClose();
                        onConfirm();
                    }}
                    color="error"
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmDialog;
