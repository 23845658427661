// @ts-ignore
import { buildFields } from 'ra-data-hasura';
import gql from 'graphql-tag';

const extractFieldsFromQuery = (queryAst: any) => {
    return queryAst.definitions[0].selectionSet.selections;
};

const GET_LIST_USER = gql`
              {
                id: level
                iso: c_id
              }
            `;

const GET_LIST_PANEL_CONFIG_KEYS = gql`
              {
                id
                key
                label
                type
                panel_config_key_values{
                    id
                    label
                    value
                }
   
              }
            `;

const GET_ONE_PANEL_CONFIG_KEYS = gql`
              {
                id
                key
                label
                type
                panel_config_key_values{
                    id
                    label
                    value
                }
   
              }
            `;

const GET_ONE_SUB_PANEL_DEMAND_SOURCE = gql`
              {
                id
                sub_panel_id
                feed_max_projects
                demand_source
                sub_panel {
                    panel_name
                }
                min_in_field_conversion_rate
                max_order_loi
                min_order_ir
                max_loi_change_percent
                min_margin
                payout_percentage
                feed_strategy
                feed_priority
              }
            `;

const GET_ONE_SUB_PANEL = gql`
              {
                id
                panel_id
                panel_uuid
                panel_name
                sub_panel_locales_info {
                    id
                    code
                }


              }
            `;

const GET_LIST_SUB_PANEL_DEMAND_SOURCE = gql`
              {
                id
                sub_panel_id
                feed_max_projects
                demand_source
                sub_panel {
                    panel_name
                }
              }
            `;

const GET_LIST_PROFILER = gql`
              {
                id
                name
                text
                external_id
                demand_source {
                    id
                    name
                }
              }
            `;

const GET_ONE_PROFILER = gql`
              {
                id
                demand_source_id
                name
                text
                external_id
                demand_source {
                    id
                    name
                }
                profiler_answers {
                    id
                    external_id
                    text
                    profiler_variable_answer_maps {
                        data_variable_option {
                            id
                            name
                            data_variable_id
                        }
                    }
                }
                profiler_variable_question_maps {
                    data_variable {
                        id
                        name
                        type,
                        uuid,
                        data_variable_options(order_by: {name: asc})  {
                            id
                            name
                        }   
                    }
                }
              }
            `;

const GET_ONE_DATA_VARIABLE = gql`
              {
                id
                uuid
                name
                type
                data_variable_options(order_by: {name: asc}) {
                    id
                    name
                    data_variable_id
                }


              }
            `;

const customBuildFields = (type: any, fetchType: any) => {
    const resourceName = type.name;

    if (resourceName === 'ccommunity_geopc_regions' && fetchType === 'GET_LIST') {
        return extractFieldsFromQuery(GET_LIST_USER);
    }
    if (resourceName === 'oursurveys_sub_panel_demand_sources' && fetchType === 'GET_LIST') {
        return extractFieldsFromQuery(GET_LIST_SUB_PANEL_DEMAND_SOURCE);
    }
    if (resourceName === 'community_panel_config_keys' && fetchType === 'GET_LIST') {
        return extractFieldsFromQuery(GET_LIST_PANEL_CONFIG_KEYS);
    }
    if (resourceName === 'community_panel_config_keys' && fetchType === 'GET_ONE') {
        return extractFieldsFromQuery(GET_ONE_PANEL_CONFIG_KEYS);
    }
    if (resourceName === 'oursurveys_sub_panel_demand_sources' && fetchType === 'GET_ONE') {
        return extractFieldsFromQuery(GET_ONE_SUB_PANEL_DEMAND_SOURCE);
    }
    if (resourceName === 'community_sub_panels' && fetchType === 'GET_ONE') {
        return extractFieldsFromQuery(GET_ONE_SUB_PANEL);
    }
    if (resourceName === 'oursurveys_profilers' && fetchType === 'GET_ONE') {
        return extractFieldsFromQuery(GET_ONE_PROFILER);
    }
    if (resourceName === 'oursurveys_profilers' && fetchType === 'GET_LIST') {
        return extractFieldsFromQuery(GET_LIST_PROFILER);
    }
    if (resourceName === 'oursurveys_data_variables' && fetchType === 'GET_ONE') {
        return extractFieldsFromQuery(GET_ONE_DATA_VARIABLE);
    }

    // No custom query defined, so use the default query fields (all, but no related/nested).
    return buildFields(type, fetchType);
};

export default customBuildFields;