import React from 'react'
import {
    List,
    Datagrid,
    TextField, ShowButton, TextInput
} from 'react-admin'

const filters = [
    <TextInput label="ID search" source="id" resettable alwaysOn style={{width: 90}} />,
    <TextInput label="Text search" source="bundle" resettable alwaysOn style={{width: 500}} />,
];

const TranslationBundlesList = (props: any) => {
    return (
        <List filters={filters} bulkActionButtons={false} perPage={50} {...props} >
            <Datagrid>
                <TextField source='id' />
                <TextField source='bundle' />
                <ShowButton label="Edit translations" style={{width: '200px'}} />
            </Datagrid>
        </List>
    )
}

export default TranslationBundlesList
