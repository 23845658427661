import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {ReactComponent as Logo} from "./pp-logo.svg";
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100vh',
        background: 'linear-gradient(90deg, rgba(41,113,146,1) 0%, rgba(35,164,163,1) 35%, rgba(41,113,146,1) 100%)',
    },
    logo: {
        width: 150,
        marginBottom: 40
    },
    paper: {
        background: 'white',
        padding: '20px 40px',
        borderRadius: 5,
    },
    link: {
        fontSize: 12,
        textDecoration: 'none !important',
        marginTop: '10px !important'
    }
}));
// useStyles();

const MyLoginPage = () => {
    const notify = useNotify();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [missing, setMissing] = useState<{
        email: boolean,
        password: boolean
    }>({email: false, password: false})
    const login = useLogin();
    const classes = useStyles();
    const emailRef = useRef(null);

    useEffect(() => {
        if(localStorage.getItem("auth")){
            window.location.href = '/'
        }
    }, []);

    const handleBlur = (key: 'email' | 'password') => () => {
        if (key === 'email') {
            setMissing({
                ...missing,
                email: email.trim() === ''
            })
        }else{
            setMissing({
                ...missing,
                password: password.trim() === ''
            })
        }
    };

    const submit = (e: any) => {
        e.preventDefault();
        // will call authProvider.login({ email, password })
        if (missing.email === false && missing.password === false)
        login({ username: email, password }).catch(() =>{
                notify(`Please check your credentials`, { type: 'error' });
            }

        );
    };

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.container}
        >

            <Grid item xs={3}
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center">
                <Logo className={classes.logo} />
                <Grid
                    container
                    xs={3}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.paper}
                >
                    <TextField label="Email" variant="standard" fullWidth
                               value={email}
                               autoFocus
                               onChange={e => {
                                   setEmail(e.target.value)
                                   setMissing({
                                       ...missing,
                                       email: false
                                   })
                               }}
                               style={{marginBottom: 5}}
                               ref={emailRef}
                               onBlur={handleBlur('email')}
                               error={missing.email}
                               helperText={ missing.email && 'Email is required' }
                    />
                    <TextField label="Password" variant="standard" fullWidth
                               value={password}
                               onChange={e => {
                                   setPassword(e.target.value)
                                   setMissing({
                                       ...missing,
                                       password: false
                                   })
                               }}
                               type="password"
                               onBlur={handleBlur('password')}
                               error={missing.password}
                               helperText={ missing.password && 'Password is required' }
                               />

                    <Button variant="contained" fullWidth style={{marginTop: 50}}
                        onClick={submit}
                    >
                        SIGN IN
                    </Button>
                    <Link href="/#/forgot-password" className={classes.link}>Forgot Password?</Link>
                </Grid>
            </Grid>
            <Notification/>
        </Grid>

    );
};

export default MyLoginPage;