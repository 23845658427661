import React, {useEffect, useState} from 'react'
import {
    Edit,
    SimpleForm,
    TextField,
    TextInput,
    NumberInput,
    useDataProvider
} from 'react-admin'
import Typography from '@mui/material/Typography';
import InputLabel from "@material-ui/core/InputLabel";

export const SubPanelDemandSourcesEdit = (props:any) => {

    const dataProvider = useDataProvider();

    const [record, setRecord] = useState<any>(null);

    useEffect(() => {

        dataProvider
            .getOne("oursurveys_sub_panel_demand_sources", {
                id: props.id
            })
            .then(({ data }) => {
                setRecord(data);
            })
            .catch((error) => {

            });

        dataProvider
            .getList("community_panel_config_keys", {
                pagination: { page: 1, perPage: 10000 },
                sort: { field: "id", order: "ASC" },
                filter: {},
            })
            .then(({ data }) => {

            })
            .catch((error) => {
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return(
        <Edit {...props}>
            <SimpleForm>
                <TextField source="id" />
                <TextField source="sub_panel_id" />
                {record &&
                  <>
                    <InputLabel style={{ fontSize: 12, marginTop: 20, marginBottom: 10}}>Panel name</InputLabel>
                    <Typography style={{ marginBottom: 30}}>
                        {record.sub_panel.panel_name}
                    </Typography>
                  </>
                }
                <NumberInput source="feed_max_projects" />
                <TextInput source="min_in_field_conversion_rate" />
                <TextInput source="max_order_loi" />
                <TextInput source="min_order_ir" />
                <TextInput source="max_loi_change_percent" />
                <TextInput source="min_margin" />
                <TextInput source="payout_percentage" />
                <TextInput source="feed_strategy" />
                <TextInput source="feed_priority" />
            </SimpleForm>
        </Edit>
    )
}

export default SubPanelDemandSourcesEdit
