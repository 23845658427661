import * as React from 'react';
import {useEffect, useState} from 'react';
import {Notification, useNotify} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {ReactComponent as Logo} from "./pp-logo.svg";
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const { REACT_APP_API_AUTH_HOST } = process.env;

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100vh',
        background: 'linear-gradient(90deg, rgba(41,113,146,1) 0%, rgba(35,164,163,1) 35%, rgba(41,113,146,1) 100%)',
    },
    logo: {
        width: 150,
        marginBottom: 40
    },
    paper: {
        background: 'white',
        padding: '20px 40px',
        borderRadius: 5,
    },
    link: {
        fontSize: 12,
        textDecoration: 'none !important',
        marginTop: '10px !important'
    }
}));
// useStyles();

const ResetPassword = (props: any) => {
    const notify = useNotify();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('')
    const [sent, setSent] = useState(false);
    const [tokenError, setTokenError] = useState(false);
    const classes = useStyles();

    useEffect(() => {

    }, []);

    // const handleBlur = (key: 'email' | 'password') => () => {
    //     console.log(key);
    //     setMissing(email.trim() === '');
    // };

    const handleUpdatePassword = () => {
        const token = window.location.href.split('token=')[1];

        if (password === confirmPassword){
            if (password.length < 6) {
                notify(`Your password must be at least 6 characters long.`, { type: 'error' });
                return;
            }
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    password: password,
                    token
                })
            };
            fetch(`${REACT_APP_API_AUTH_HOST}/v1/user/reset-password`, requestOptions)
                .then((data) => {
                    if (data.status === 204) {
                        setSent(true);
                    }else{
                        setTokenError(true);
                    }
                    // setSent(true);
                });
        } else {
            notify(`Password and Confirmation do not match.`, { type: 'error' });
        }
    };

    const backToLogin = () => {
        window.location.href = '/#/login'
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.container}
        >

            <Grid item xs={3}
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center">
                <Logo className={classes.logo} />
                <Grid
                    container
                    xs={3}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.paper}
                >
                    {!sent && !tokenError ?
                        <>
                            <h3 style={{width: '100%'}}>Password reset</h3>
                            <Typography style={{width: '100%', fontSize: 14, marginBottom: 20}}>
                                Please enter a new password
                            </Typography>
                            <TextField label="Password" variant="standard" fullWidth
                                       value={password}
                                       autoFocus
                                       onChange={e => {
                                           setPassword(e.target.value)
                                       }}
                                       style={{marginBottom: 5}}
                                       type="password"
                                       // onBlur={handleBlur('email')}
                                       // error={missing}
                                       // helperText={ missing && 'Email is required' }
                            />
                            <TextField label="Confirm password" variant="standard" fullWidth
                                       value={confirmPassword}
                                       onChange={e => {
                                           setConfirmPassword(e.target.value)
                                       }}
                                       style={{marginBottom: 5}}
                                       type="password"
                                // onBlur={handleBlur('email')}
                                // error={missing}
                                // helperText={ missing && 'Email is required' }
                            />
                            <Button variant="contained" fullWidth style={{marginTop: 50}}
                                    onClick={handleUpdatePassword}
                            >
                                CHANGE PASSWORD
                            </Button>
                            <Link href="/#/login" className={classes.link}>Back to login?</Link>
                        </> :
                        <>
                            {sent ?
                                <>
                                    <h3 style={{width: '100%'}}>Success!</h3>
                                    <Typography style={{width: '100%', fontSize: 14, marginBottom: 20}}>
                                        Your password is reset successfully!
                                    </Typography>

                                    <Button variant="contained" fullWidth style={{marginTop: 50}}
                                            onClick={backToLogin}
                                    >
                                        BACK TO LOGIN
                                    </Button>
                                </> :
                                <>
                                    <h3 style={{width: '100%'}}>Something went wrong.</h3>
                                    <Typography style={{width: '100%', fontSize: 14, marginBottom: 20}}>
                                        The link might have expired or the token is wrong. Please try again.
                                    </Typography>

                                    <Button variant="contained" fullWidth style={{marginTop: 50}}
                                            onClick={backToLogin}
                                    >
                                        BACK TO LOGIN
                                    </Button>
                                </>
                            }
                        </>

                    }

                </Grid>
            </Grid>
            <Notification/>
        </Grid>

    );
};

export default ResetPassword;