import React, {useEffect, useState} from 'react'
import {
    List,
    Datagrid,
    TextField, EditButton,
    SelectInput, useDataProvider
} from 'react-admin'

const SubPanelDemandSourcesList = (props: any) => {

    const dataProvider = useDataProvider();
    const [subPanels, setSubPanels] = useState<any>({});
    useEffect(() => {
        dataProvider
            .getList("oursurveys_sub_panels", {
                pagination: { page: 1, perPage: 10000 },
                sort: { field: "id", order: "ASC" },
                filter: {},
            })
            .then(({ data }) => {
                const modSubPanels = JSON.parse(JSON.stringify(subPanels));
                data.forEach((subPanel: any) => {
                    modSubPanels[subPanel.id] = subPanel.panel_name;
                })
                setSubPanels(modSubPanels);
            })
            .catch((error) => {

            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filters = [
        <SelectInput
            choices={Object.keys(subPanels).map((subPanelId) => {
                return {
                    id: subPanelId,
                    name: subPanels[subPanelId]
                }
            })}
            source="sub_panel_id"
            alwaysOn
            resettable
            style={{width: 300}}
        />,
        <SelectInput
            choices={[
                { id: 'cint', name: 'cint' },
                { id: 'panel', name: 'panel' }
            ]}
            source="demand_source"
            alwaysOn
            resettable
            style={{width: 300}}
        />,
    ];

    return (
        <List filters={filters} bulkActionButtons={false} perPage={50} {...props} >
            <Datagrid>
                <TextField source='id' />
                <TextField source='sub_panel_id' />
                <TextField
                    label="Panel Name"
                    source='sub_panel.panel_name'
                />;
                <TextField source='feed_max_projects' />
                <TextField source='demand_source' />
                <EditButton label="Edit" style={{width: '200px'}} />
            </Datagrid>
        </List>
    )
}

export default SubPanelDemandSourcesList
