import React from 'react'
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    DateField,
    ShowButton,
    EditButton,
    TextInput
} from 'react-admin'


const filters = [
    <TextInput label="Search by name" source="name" resettable alwaysOn style={{width: 500}} />,
];

const CommunityWorkersList = (props: any) => {
    return (
        <List filters={filters} bulkActionButtons={false} perPage={50} {...props} >
            <Datagrid>
                <TextField source='id' />
                <TextField source='name' />
                <BooleanField source='is_active' />
                <TextField source='interval_secs' />
                <DateField source="last_success" showTime={true} />
                <DateField source="last_failure" showTime={true} />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    )
}

export default CommunityWorkersList
