import React from 'react'
import {
    List,
    Datagrid,
    TextField
} from 'react-admin'

const LanguagesList = (props: any) => {
    return (
        <List bulkActionButtons={false} perPage={50} {...props} >
            <Datagrid>
                <TextField source='id' />
                <TextField source='iso639_2_code' />
                <TextField source='iso639_1_code' />
                <TextField source='name' />
            </Datagrid>
        </List>
    )
}

export default LanguagesList
