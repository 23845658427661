import React from 'react'
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    TextInput
} from 'react-admin'

const filters = [
    <TextInput label="Search" source="search_text" resettable alwaysOn style={{width: 500}} />,
];

const GeopcPlacesList = (props: any) => {
    return (
        <List filters={filters} bulkActionButtons={false} perPage={50} {...props} >
            <Datagrid>
                <TextField source='id' />
                <TextField source='iso' />
                <TextField source='country' />
                <TextField source='language' />
                <TextField source='locality' />
                <TextField source='postcode' />
                <TextField source='suburb' />
                <ShowButton />
            </Datagrid>
        </List>
    )
}

export default GeopcPlacesList
