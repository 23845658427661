import * as React from 'react';
import {useEffect, useState} from 'react';
import { Notification } from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {ReactComponent as Logo} from "./pp-logo.svg";
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';


const { REACT_APP_API_AUTH_HOST } = process.env;

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100vh',
        background: 'linear-gradient(90deg, rgba(41,113,146,1) 0%, rgba(35,164,163,1) 35%, rgba(41,113,146,1) 100%)',
    },
    logo: {
        width: 150,
        marginBottom: 40
    },
    paper: {
        background: 'white',
        padding: '20px 40px',
        borderRadius: 5,
    },
    link: {
        fontSize: 12,
        textDecoration: 'none !important',
        marginTop: '10px !important'
    }
}));
// useStyles();

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [missing, setMissing] = useState(false)
    const [sent, setSent] = useState(false);
    const classes = useStyles();

    useEffect(() => {

    }, []);

    const handleBlur = (key: 'email' | 'password') => () => {
        setMissing(email.trim() === '');
    };

    const handleForgotPassword = () => {
        if (!missing){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email })
            };
            fetch(`${REACT_APP_API_AUTH_HOST}/v1/user/forgot-password`, requestOptions)
                .then(() => {
                    setSent(true);
                });
        }
    };

    const backToLogin = () => {
        window.location.href = '/#/login'
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.container}
        >

            <Grid item xs={3}
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center">
                <Logo className={classes.logo} />
                <Grid
                    container
                    xs={3}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.paper}
                >
                    {!sent ?
                        <>
                            <h3 style={{width: '100%'}}>Forgot your password?</h3>
                            <Typography style={{width: '100%', fontSize: 14, marginBottom: 20}}>
                                Please enter your email to search for your account
                            </Typography>
                            <TextField label="Email" variant="standard" fullWidth
                                       value={email}
                                       autoFocus
                                       onChange={e => {
                                           setEmail(e.target.value)
                                           setMissing(false)
                                       }}
                                       style={{marginBottom: 5}}

                                       onBlur={handleBlur('email')}
                                       error={missing}
                                       helperText={ missing && 'Email is required' }
                            />
                            <Button variant="contained" fullWidth style={{marginTop: 50}}
                                    onClick={handleForgotPassword}
                            >
                                RECEIVE EMAIL INSTRUCTIONS
                            </Button>
                            <Link href="/#/login" className={classes.link}>Back to login?</Link>
                        </> :

                        <>
                            <h3 style={{width: '100%'}}>Check your email</h3>
                            <Typography style={{width: '100%', fontSize: 14, marginBottom: 20}}>
                                If the email address you entered is registered with us,
                                you have received an email with instructions on how to reset your password.
                            </Typography>

                            <Button variant="contained" fullWidth style={{marginTop: 50}}
                                    onClick={backToLogin}
                            >
                                BACK TO LOGIN
                            </Button>
                        </>
                    }

                </Grid>
            </Grid>
            <Notification/>
        </Grid>

    );
};

export default ForgotPassword;