import React, {useState} from 'react'
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    useRecordContext,
    Identifier, useDataProvider, useNotify, useRefresh
} from 'react-admin'
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { PanelConfigKey } from "./interfaces/panel-config-key";
import Typography from "@mui/material/Typography";
import ConfirmDialog from "../../../shared/confirmDialog";

const PanelConfigKeysList = (props: any) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
    const [toDelete, setToDelete] = useState<PanelConfigKey | null>(null);

    const initiateDelete = (key: PanelConfigKey) => () => {
        setToDelete(key);
        setConfirmOpen(true);
    }

    const handleDelete = async() =>{
        await dataProvider
            .getList("community_panel_config_values", {
                pagination: { page: 1, perPage: 10000 },
                sort: { field: "id", order: "ASC" },
                filter: { panel_config_key_id: [toDelete!.id] },
            })
            .then(({ data }) => {
                const ids = data.flatMap(key => key.id)

                dataProvider.deleteMany("community_panel_config_values",  {
                    ids: ids
                })
                    .then(({ data }) => {
                    })
                    .catch((error) => {

                    });

            })
            .catch((error) => {

            });

        if(toDelete!.type === 'select' ){
            await dataProvider
                .getList("community_panel_config_key_values", {
                    pagination: { page: 1, perPage: 10000 },
                    sort: { field: "id", order: "ASC" },
                    filter: { panel_config_key_id: [toDelete!.id] },
                })
                .then(({ data }) => {
                    const ids = data.flatMap(key => key.id)

                    dataProvider.deleteMany("community_panel_config_key_values",  {
                        ids: ids
                    })
                        .then(({ data }) => {

                        })
                        .catch((error) => {

                        });

                })
                .catch((error) => {

                });
        }

        dataProvider.delete("community_panel_config_keys",  {
            id: toDelete!.id as Identifier
        })
            .then(({ data }) => {
                // notify(`Bundle key deleted`, { type: 'success' });
                refresh();
                notify(`Key deleted.`, { type: 'success' });
            })
            .catch((error) => {

            });

    }
    const DeleteButton = (props: any) => {
        const record = useRecordContext(props);
        return record ? <Button
            onClick={initiateDelete(record as PanelConfigKey)}
            color="error"
            startIcon={<DeleteIcon/>}
        >Delete</Button> : null;
    }

    return (
        <>
            <List bulkActionButtons={false} perPage={50} {...props} >
                <Datagrid>
                    <TextField source='id' />
                    <TextField source='key' />
                    <TextField source='label' />
                    <TextField source='type' />
                    <EditButton />
                    <DeleteButton/>
                </Datagrid>

            </List>
            <ConfirmDialog
                title="Delete Bundle key?"
                open={confirmOpen}
                onConfirm={handleDelete}
                onClose={()=>{ setConfirmOpen(false)}}
            >
                <Typography>
                    Are you sure you want to delete this key? This action is irreversible.
                    All panel settings related to it will also be deleted.
                </Typography>
            </ConfirmDialog>
        </>

    )
}

export default PanelConfigKeysList
