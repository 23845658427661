import React from 'react'
import {
    Create,
    SimpleForm,
    TextInput
} from 'react-admin'

const TranslationBundlesCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="bundle" />
            </SimpleForm>
        </Create>

    )
}

export default TranslationBundlesCreate
