import React, {Fragment, useEffect, useState} from 'react'
import {
    List,
    Datagrid,
    TextField, ShowButton,
    DeleteButton, TextInput,
    SelectInput, BulkDeleteButton,
    FunctionField, useDataProvider
} from 'react-admin'

interface LeadStatus {
    id: number;
    name: string;
}
const CommunityLeadsList = (props: any) => {
    const dataProvider = useDataProvider();

    const [rawStatuses, setRawStatuses] = useState<LeadStatus[]>([])
    const [statuses, setStatuses] = useState<{[id: number]: string}> ({});

    useEffect(() => {
        dataProvider
            .getList("community_lead_status", {
                pagination: { page: 1, perPage: 100 },
                sort: { field: "id", order: "ASC" },
                filter: {},
            })
            .then(({ data }) => {
                setRawStatuses((data) as LeadStatus[])
                setStatuses(data.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.name }), {}));
            })
            .catch((error) => {

            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filters = [
        <SelectInput source="lead_status_id" resettable alwaysOn choices={rawStatuses} />,

        <SelectInput source="locale" alwaysOn choices={[
            { id: 'DUT-NL', name: 'DUT-NL' },
            { id: 'ENG-GB', name: 'ENG-GB' },
            { id: 'ENG-US', name: 'ENG-US' },
            { id: 'GER-DE', name: 'GER-DE' },
            { id: 'FRE-BE', name: 'FRE-BE' },
            { id: 'DUT-BE', name: 'DUT-BE' },
            { id: 'ENG-AU', name: 'ENG-AU' }
        ]} />,
        <SelectInput source="recruitment_platform" style={{width: 200}} alwaysOn choices={[
            { id: 'leadbyte', name: 'leadbyte' }
        ]} />,
        <TextInput label="Email search" source="email" resettable alwaysOn style={{width: 500}} />,
    ];

    const BulkActionButtons = (props:any) => (
        <Fragment>
            <BulkDeleteButton undoable={false} {...props}  />
        </Fragment>
    );

    return (
        <List filters={filters} bulkActionButtons={<BulkActionButtons />} perPage={25} {...props}>
            <Datagrid>
                <TextField source='id' />
                <FunctionField label="Name"
                               render={ (record: any) => `${statuses[record.lead_status_id]}`} />
                <TextField source='lead_status_id' />
                <TextField source='sub_panel_id' />
                <TextField source='locale' />
                <TextField source='recruitment_platform' />
                <TextField source='recruitment_source' />
                <TextField source='email' />
                <ShowButton />
                <DeleteButton mutationMode="pessimistic"/>
            </Datagrid>
        </List>
    )
}

export default CommunityLeadsList
