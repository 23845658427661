import React from 'react'
import {
    List,
    Datagrid,
    TextField,
    ShowButton, TextInput
} from 'react-admin'

const filters = [
    <TextInput label="Country" source="country" resettable alwaysOn style={{width: 300}} />,
    <TextInput label="Region name" source="name" resettable alwaysOn style={{width: 300}} />,
];

const GeopcRegionsList = (props: any) => {
    return (
        <List filters={filters}
              bulkActionButtons={false} sort={{ field: 'level', order: 'asc' }} perPage={50} {...props} >
            <Datagrid>
                <TextField source='id' />
                <TextField source='iso' />
                <TextField source='country' />
                <TextField source='language' />
                <TextField source='level' />
                <TextField source='type' />
                <TextField source='name' />
                <ShowButton />
            </Datagrid>
        </List>
    )
}

export default GeopcRegionsList
