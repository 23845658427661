import React from "react";
import { Show, SimpleShowLayout, TextField } from 'react-admin';

const GeopcPlacesShow = (props: any) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source='id' />
            <TextField source="iso" />
            <TextField source="country" />
            <TextField source="language" />
            <TextField source="level" />
            <TextField source="type" />
            <TextField source="name" />
            <TextField source="region1" />
            <TextField source="region2" />
            <TextField source="region3" />
            <TextField source='region4' />
            <TextField source='iso2' />
            <TextField source='fips' />
            <TextField source="nuts" />
            <TextField source="hasc" />
            <TextField source="stat" />
            <TextField source='unique_id' />
        </SimpleShowLayout>
    </Show>
);

export default GeopcPlacesShow
