import React, {useState, useEffect, useRef} from "react";
import { useHistory } from "react-router-dom";
import {
    Show, SimpleShowLayout, TextField as RaTextField, useDataProvider, useNotify
} from 'react-admin';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'
import find from 'lodash/find';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ChipInput from 'material-ui-chip-input'
import ConfirmDialog from "../../../shared/confirmDialog";
import filter from "lodash/filter";
import Paper from '@mui/material/Paper';


const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



const TranslationBundlesEdit = (props: any) => {

    const history = useHistory();
    const dataProvider = useDataProvider();
    const [locales, setLocales] = useState<any[]>([]);
    const [bundleKeys, setBundleKeys] = useState<any[]>([]);
    const [translations] = useState<any[]>([]);
    const [bundleKeysWithTranslations, setBundleKeysWithTranslations] = useState<any[]>([]);

    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
    const [confirmDeleteBundleOpen, setConfirmDeleteBundleOpen] = useState<boolean>(false);
    const [toDelete, setToDelete] = useState<number|null>(null);
    const initialTranslations = useRef<any[]>(translations);
    const notify = useNotify();

    const [expanded, setExpanded] = useState<string | false>(false);
    const [newKeyDialogOpen, setNewKeyDialogOpen] = useState(false);
    const [keyChips, setKeyChips] = useState<string[]>([]);

    const [newJSONKeyDialogOpen, setNewJSONKeyDialogOpen] = useState(false);
    const [newJSONKeyBundleId, setNewJSONKeyBundleId] = useState(0);
    const [newJSONKey, setNewJSONKey] = useState('');

    const handleOpenDialog = () => {
        setNewKeyDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setKeyChips([]);
        setNewKeyDialogOpen(false);
    };

    const handleOpenJSONKeyDialog = (bundleKeyId: number)=> () => {
        setNewJSONKeyBundleId(bundleKeyId);
        setNewJSONKeyDialogOpen(true);
    };
    const handleCloseJSONKeyDialog = () => {
        setNewJSONKeyDialogOpen(false);
    };

    const handleNewJSONKeyChange = (event: any) => {
        setNewJSONKey(event.target.value);
    }

    const handleKeyChipsChange = (chips: any) =>{
        setKeyChips(chips);
    }

    const handleAddKeys = async () => {

        const newBundleKeys: any[] = [];
        await Promise.all(keyChips.map(async (chip) => {
            await dataProvider.create('community_translation_bundle_keys', {
                data: {
                    bundle_id: props.id,
                    mnemonic: chip
                },
            })
                .then(({data}) => {
                    newBundleKeys.push(data);
                })
                .catch((error) => {

                });
        }));

        setKeyChips([]);
        setNewKeyDialogOpen(false);
        setBundleKeys([...bundleKeys, ...newBundleKeys]);

    }

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    useEffect(() => {
        dataProvider
            .getList("community_locales", {
                pagination: { page: 1, perPage: 10000 },
                sort: { field: "id", order: "ASC" },
                filter: {},
            })
            .then(({ data }) => {
                setLocales(data);
            })
            .catch((error) => {

            });

        dataProvider
            .getList("community_translation_bundle_keys", {
                pagination: { page: 1, perPage: 10000 },
                sort: { field: "id", order: "ASC" },
                filter: { bundle_id: props.id},
            })
            .then(({ data }) => {
                setBundleKeys(data);
            })
            .catch((error) => {

            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(locales.length > 0 ){
            dataProvider
                .getList("community_translations", {
                    pagination: { page: 1, perPage: 10000 },
                    sort: { field: "id", order: "ASC" },
                    filter: { bundle_key_id: bundleKeys.flatMap(bk => bk.id)}
                })
                .then(({ data }) => {
                    initialTranslations.current = data;

                    const bundleKeysWithTranslations = JSON.parse(JSON.stringify(bundleKeys));
                    bundleKeysWithTranslations.forEach((bk: any) => {
                        bk.isJSON = false;
                        bk.initiated = false;
                        bk.translations = filter(data, {bundle_key_id: bk.id});
                        if (bk.translations.length > 0 && typeof(bk.translations[0].value) === 'object'){
                            bk.isJSON = true;
                            bk.keys = {};
                            const keys = Object.keys(bk.translations[0].value);
                            keys.forEach((key: string) => {
                                bk.keys[key] = {}
                            })

                            locales.forEach((locale: any) => {
                                const translation = find(bk.translations, {locale_code: locale.code})

                                Object.keys(bk.keys).forEach((key: string) => {
                                    if(translation && translation.value[key]) {
                                        bk.keys[key][locale.code] = translation.value[key];
                                    } else {
                                        bk.keys[key][locale.code] = '';
                                    }
                                });
                            })
                        }

                    });

                    setBundleKeysWithTranslations(bundleKeysWithTranslations);

                })
                .catch((error) => {

                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[bundleKeys, locales]);

    const handleStringTranslationChange = (bundleKey: any, localeCode: string) => (event: any) => {

        const modifiedBundleKeysWithTranslations = JSON.parse(JSON.stringify(bundleKeysWithTranslations));
        const modifiedBundleKeyWithTranslation = find (
            modifiedBundleKeysWithTranslations,
                {id: bundleKey.id}
            );


        const modifiedTranslation = find(modifiedBundleKeyWithTranslation.translations, {locale_code: localeCode});
        if (modifiedTranslation) {
            modifiedTranslation.value = event.target.value

        } else {
            const newTranslation: any = {
                bundle_key_id: bundleKey.id,
                locale_code: localeCode,
                value: event.target.value
            }
            modifiedBundleKeyWithTranslation.translations.push(newTranslation)

        }
        setBundleKeysWithTranslations(modifiedBundleKeysWithTranslations);
    }

    const handleJSONTranslationChange = (bundleKey: any, key: string, localeCode: string) => (event: any) => {

        const modifiedBundleKeysWithTranslations = JSON.parse(JSON.stringify(bundleKeysWithTranslations));
        const modifiedBundleKeyWithTranslation = find (
            modifiedBundleKeysWithTranslations,
            {id: bundleKey.id}
        );

        modifiedBundleKeyWithTranslation.keys[key][localeCode] = event.target.value;
        setBundleKeysWithTranslations(modifiedBundleKeysWithTranslations);
    }

    const handleAddNewJSONKey = () => {
        const modifiedBundleKeysWithTranslations = JSON.parse(JSON.stringify(bundleKeysWithTranslations));
        const modifiedBundleKeyWithTranslation = find (
            modifiedBundleKeysWithTranslations,
            {id: newJSONKeyBundleId}
        );
        if (!modifiedBundleKeyWithTranslation.keys) {
            modifiedBundleKeyWithTranslation.keys = {};
        }
        modifiedBundleKeyWithTranslation.keys[newJSONKey] = {};
        locales.forEach((locale: any) => {
            modifiedBundleKeyWithTranslation.keys[newJSONKey][locale.code] = '';
        })
        setBundleKeysWithTranslations(modifiedBundleKeysWithTranslations);
        setNewJSONKeyDialogOpen(false);

    }

    const handleDeleteJSONKey = (bundleId: number, key: string) => () => {
        const modifiedBundleKeysWithTranslations = JSON.parse(JSON.stringify(bundleKeysWithTranslations));
        const modifiedBundleKeyWithTranslation = find (
            modifiedBundleKeysWithTranslations,
            {id: bundleId}
        );
        delete modifiedBundleKeyWithTranslation.keys[key];
        setBundleKeysWithTranslations(modifiedBundleKeysWithTranslations);
    }

    const stringKeyPress = (bundleId: number, localeCode: string) => (event:any) => {
        if(event.keyCode === 13){
            saveStringTranslation(bundleId, localeCode)();
        }
    }

    const saveStringTranslation = (bundleKeyId: number, localeCode: string) => () => {

        const existingTranslation = find(
            initialTranslations.current,
            {bundle_key_id: bundleKeyId, locale_code: localeCode}
        );
        const bundleKeyWithTranslations = find(bundleKeysWithTranslations, {id: bundleKeyId});
        const modifiedTranslation = find(bundleKeyWithTranslations.translations, {locale_code: localeCode});

        if (existingTranslation) {
            if(modifiedTranslation.value !== existingTranslation.value) {
                dataProvider.update("community_translations",  {
                    id: existingTranslation.id,
                    data: {
                        value: modifiedTranslation.value
                    },
                    previousData: existingTranslation
                })
                    .then(({ data }) => {
                        notify(`Translation saved`, { type: 'success' });
                        const modifiedTranslations = JSON.parse(JSON.stringify(initialTranslations.current));
                        const updatedTranslation = find(modifiedTranslations, {id: data.id});
                        updatedTranslation.value = data.value;
                        initialTranslations.current = modifiedTranslations;
                    })
                    .catch((error) => {

                    });
            }
        } else {
            dataProvider.create('community_translations', {
                data: modifiedTranslation,
            })
                .then(({data}) => {
                    notify(`Translation saved`, { type: 'success' });
                    const modifiedTranslations = JSON.parse(JSON.stringify(initialTranslations.current));
                    modifiedTranslations.push(data);
                    initialTranslations.current = modifiedTranslations;
                })
                .catch((error) => {

                });
        }

    }

    const handleSaveBundleKey = (bundleKeyId: number) => async () => {

        const modifiedBundleKeysWithTranslations = JSON.parse(JSON.stringify(bundleKeysWithTranslations));
        const modifiedBundleKeyWithTranslations = find(modifiedBundleKeysWithTranslations, {id: bundleKeyId});

        const translationsToCreate: any[] = [];
        const translationsToUpdate: any[] = [];

        locales.forEach((locale: any) => {
            const existingTranslation = find(modifiedBundleKeyWithTranslations.translations,
                {bundle_key_id: bundleKeyId, locale_code: locale.code}
            )
            const translationValue: any = {};
            Object.keys(modifiedBundleKeyWithTranslations.keys).forEach((key: string) => {
                translationValue[key] = modifiedBundleKeyWithTranslations.keys[key][locale.code];
            })

            if (existingTranslation){
                translationsToUpdate.push({
                    existingTranslation: existingTranslation,
                    updatedValue: translationValue
                })
            } else {
                translationsToCreate.push({
                    bundle_key_id: bundleKeyId,
                    locale_code: locale.code,
                    value: translationValue
                })
            }

        });

        const createdTranslations: any[] = [];
        const updatedTranslations: any[] = [];
        await Promise.all(translationsToUpdate.map(async (translation: any) => {
            await dataProvider.update("community_translations",  {
                id: translation.existingTranslation.id,
                data: {
                    value: translation.updatedValue
                },
                previousData: translation
            })
                .then(({ data }) => {
                    updatedTranslations.push(data);
                })
                .catch((error) => {

                });

        }));
        await Promise.all(translationsToCreate.map(async (translation: any) => {

            await dataProvider.create('community_translations', {
                data: translation,
            })
                .then(({data}) => {
                    createdTranslations.push(data);
                })
                .catch((error) => {

                });
        }));
        modifiedBundleKeyWithTranslations.translations = [...createdTranslations, ...updatedTranslations];
        notify(`Translations saved`, { type: 'success' });
        setBundleKeysWithTranslations(modifiedBundleKeysWithTranslations);
    }

    const initiateValues = (bundleKeyId: number, type: 'json' | 'string') => () => {
        const modifiedBundleKeysWithTranslations = JSON.parse(JSON.stringify(bundleKeysWithTranslations));
        const modifiedBundleKeyWithTranslation = find (
            modifiedBundleKeysWithTranslations,
            {id: bundleKeyId}
        );
        if (type === 'json') {
            modifiedBundleKeyWithTranslation.isJSON = true;
        }
        modifiedBundleKeyWithTranslation.initiated = true;
        setBundleKeysWithTranslations(modifiedBundleKeysWithTranslations);
    }

    const initiateBundleKeyDelete = (id: number) => () => {
        setToDelete(id);
        setConfirmOpen(true);
    }

    const handleDeleteBundleKey = () => {
        dataProvider
            .getList("community_translations", {
                pagination: { page: 1, perPage: 10000 },
                sort: { field: "id", order: "ASC" },
                filter: { bundle_key_id: toDelete },
            })
            .then(({ data }) => {
                const ids = data.flatMap(translation => translation.id)

                dataProvider.deleteMany("community_translations",  {
                    ids: ids
                })
                    .then(({ data }) => {
                        dataProvider.delete("community_translation_bundle_keys",  {
                            id: toDelete!
                        })
                            .then(({ data }) => {
                                notify(`Bundle key deleted`, { type: 'success' });

                                const updatedBundleKeys = filter(bundleKeys, (bk:any) => bk.id !== data.id);
                                setBundleKeys(updatedBundleKeys);

                            })
                            .catch((error) => {

                            });
                    })
                    .catch((error) => {

                    });

            })
            .catch((error) => {

            });
    }

    const handleDeleteBundle = () => {
        dataProvider
            .getList("community_translations", {
                pagination: { page: 1, perPage: 10000 },
                sort: { field: "id", order: "ASC" },
                filter: { bundle_key_id: bundleKeys.flatMap(bk => bk.id) },
            })
            .then(({ data }) => {
                const ids = data.flatMap(translation => translation.id)

                dataProvider.deleteMany("community_translations",  {
                    ids: ids
                })
                    .then(({ data }) => {
                        dataProvider.deleteMany("community_translation_bundle_keys",  {
                            ids: (bundleKeys.flatMap(bk => bk.id)) as any
                        })
                            .then(({ data }) => {
                                dataProvider.delete("community_translation_bundles",  {
                                    id: props.id
                                })
                                    .then(({ data }) => {
                                        notify(`Bundle deleted`, { type: 'success' });
                                        history.push("/community_translation_bundles");
                                    })
                                    .catch((error) => {

                                    });
                            })
                            .catch((error) => {

                            });


                    })
                    .catch((error) => {

                    });

            })
            .catch((error) => {

            });
    }


    const renderJSONFields = (bk: any) => {
        return (
            <>
                <Grid container justifyContent="flex-start" mb={1}>
                    <Button variant="outlined" color="primary" onClick={handleOpenJSONKeyDialog(bk.id)} style={{marginLeft:20}} >
                        Add new JSON key
                    </Button>
                </Grid>
                {
                    bk.keys && Object.keys(bk.keys).map((key: string) => (
                        <Paper elevation={3} style={{width: 600, padding: 20, margin: 20}}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom:4}}>
                                {key}
                                <Button variant="outlined" onClick={handleDeleteJSONKey(bk.id, key)} color="error" >
                                    Delete key
                                </Button>
                            </Box>

                            {locales.map((locale) => (
                                <Box>
                                    <TextField id="standard-basic" label={locale.code} variant="standard"
                                               style={{ minWidth: 500, marginBottom: 30 }}
                                               value={bk.keys[key][locale.code]}
                                               onChange={handleJSONTranslationChange(bk, key, locale.code)}
                                    />
                                </Box>

                            ))}
                        </Paper>
                    ))
                }
                {bk.keys && <Grid container justifyContent="flex-start" mb={1}>
                    <Button variant="outlined" color="success"
                            onClick={handleSaveBundleKey(bk.id)} style={{marginLeft:20}} size="large" >
                        Save bundle
                    </Button>
                </Grid>}
            </>


        )
    }

    const renderStringFields = (bk: any) => {
        return (
            <>
            {locales.map((locale) => (
                    // <></>
                    <Box>
                        <TextField id="standard-basic" label={locale.code} variant="standard"
                                   style={{ minWidth: 500, marginBottom: 30 }}
                                   value={find(
                                       bk.translations,
                                       {locale_code: locale.code}
                                   )?.value ?? ''
                                   }
                                   onChange={handleStringTranslationChange(bk, locale.code)}
                                   onKeyUp={stringKeyPress(bk.id, locale.code)}
                                   onBlur={saveStringTranslation(bk.id, locale.code)}
                        />
                    </Box>

                ))}
            </>
        )


    }

    const renderSelectionButtons = (bundleKeyId: number) => {
        return (
            <>
                <Typography>Which value do you want to save translations for this bundle key?</Typography>
                <Button variant="outlined" onClick={initiateValues(bundleKeyId, 'string')} style={{margin: 20}}>
                    String
                </Button>
                <Button variant="outlined" onClick={initiateValues(bundleKeyId, 'json')} style={{margin: 20}} >
                    JSON
                </Button>
            </>
        )
    }

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <Grid container justifyContent="flex-end">
                    <Button variant="outlined" onClick={()=>{setConfirmDeleteBundleOpen(true)}}  color="error" >
                        Delete bundle
                    </Button>
                </Grid>
                <RaTextField source="id"  />
                <RaTextField source='bundle' />
                <Box style={{marginTop: 50}}>
                    <Grid container justifyContent="flex-start" mb={1}>
                        <Button variant="outlined" color="primary" onClick={handleOpenDialog} >
                            Add new Bundle key
                        </Button>
                    </Grid>
                    {bundleKeysWithTranslations.map((bk) => (
                        <Accordion expanded={expanded === bk.mnemonic} onChange={handleChange(bk.mnemonic)}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <Typography>{bk.mnemonic}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container justifyContent="flex-end">
                                    <Button variant="outlined" onClick={initiateBundleKeyDelete(bk.id)} color="error" >
                                        Delete bundle key
                                    </Button>
                                </Grid>
                                {
                                    bk.translations.length > 0 || bk.initiated ?
                                        (
                                            bk.isJSON ?
                                                renderJSONFields(bk) :
                                                renderStringFields(bk)
                                        )
                                        :
                                        renderSelectionButtons(bk.id)
                                }

                            </AccordionDetails>
                        </Accordion>

                    ))}
                </Box>

                <Dialog open={newKeyDialogOpen} onClose={handleCloseDialog}>
                    <DialogTitle>Add new key</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{marginBottom: 10}}>
                            You may enter more than one key but separating them with spaces
                        </DialogContentText>
                        <ChipInput
                            fullWidth
                            defaultValue={keyChips}
                            newChipKeyCodes = {[13,32]}
                            onChange={(chips) => handleKeyChipsChange(chips)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                        <Button onClick={handleAddKeys}>Add keys</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={newJSONKeyDialogOpen} onClose={handleCloseJSONKeyDialog}>
                    <DialogTitle>Add new JSON key</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{marginBottom: 10}}>
                            Please enter the key name
                        </DialogContentText>
                        {/*<ChipInput*/}
                        {/*    fullWidth*/}
                        {/*    defaultValue={keyChips}*/}
                        {/*    newChipKeyCodes = {[13,32]}*/}
                        {/*    onChange={(chips) => handleKeyChipsChange(chips)}*/}
                        {/*/>*/}
                        <TextField id="standard-basic" label="JSON key" variant="standard"
                                   style={{ minWidth: 500, marginBottom: 30 }}
                                   value={newJSONKey}
                                   onChange={handleNewJSONKeyChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseJSONKeyDialog}>Cancel</Button>
                        <Button onClick={handleAddNewJSONKey}>Add key</Button>
                    </DialogActions>
                </Dialog>

                <ConfirmDialog
                    title="Delete Bundle key?"
                    open={confirmOpen}
                    onConfirm={handleDeleteBundleKey}
                    onClose={()=>{ setConfirmOpen(false)}}
                >
                    <Typography>
                        Are you sure you want to delete this bundle key? This action is irreversible.
                    </Typography>
                </ConfirmDialog>

                <ConfirmDialog
                    title="Delete bundle?"
                    open={confirmDeleteBundleOpen}
                    onConfirm={handleDeleteBundle}
                    onClose={()=>{ setConfirmDeleteBundleOpen(false)}}
                >
                    <Typography>
                        Are you sure you want to delete this bundle?
                        All bundle keys and current translations will be deleted.
                        This action is irreversible.
                    </Typography>
                </ConfirmDialog>

            </SimpleShowLayout>

            {/*}*/}
        </Show>
    )
};

export default TranslationBundlesEdit
