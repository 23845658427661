import React, {useState} from 'react'
import {
    List,
    Datagrid,
    TextField as RATextField,
    ShowButton,
    TextInput,
    useDataProvider, useNotify
} from 'react-admin'
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import find from 'lodash/find';



const filters = [
    <TextInput label="ID search" source="id" resettable alwaysOn style={{width: 90}} />,
    <TextInput label="Text search" source="text" resettable alwaysOn style={{width: 500}} />,
    <TextInput label="Name search" source="name" resettable alwaysOn style={{width: 250}} />,
    <TextInput label="External ID search" source="external_id" resettable alwaysOn style={{width: 200}} />,
];

const ProfilersList = (props: any) => {

    const dataProvider = useDataProvider();
    const notify = useNotify();

    const [filter, setFilter] = useState<number | undefined>(undefined);
    const [answerId, setAnswerId] = useState('')
    const [answerExternalId, setAnswerExternalId] = useState('')

    const handleAnswerIdSearchChange = (event: any) => {
        setAnswerExternalId('');
        setAnswerId(event.target.value);
    }
    const handleAnswerExternalIdSearchChange = (event: any) => {
        setAnswerId('');
        setAnswerExternalId(event.target.value);
    }

    const handleKeyUp = (event: any) => {
        if (event.keyCode === 13){
            if(answerId === '') {
                setFilter(undefined);
            }else{
                dataProvider
                    .getOne("oursurveys_profiler_answers", {
                        id: answerId
                    })
                    .then(({ data }) => {
                        setFilter(data.profiler_id)
                    })
                    .catch((error) => {
                        notify(`The answer ID you provided was not found`, { type: 'error' });
                    });
            }
        }
    }
    const handleExternalIdKeyUp = (event: any) => {
        if (event.keyCode === 13){
            dataProvider
                .getList("oursurveys_profiler_answers", {
                    pagination: { page: 1, perPage: 10000 },
                    sort: { field: "id", order: "ASC" },
                    filter: {
                        external_id: answerExternalId
                    },
                })
                .then(({ data }) => {
                    if (data.length > 0) {
                        const profiler = find(data, {external_id: answerExternalId})
                        if(profiler){
                            setFilter(profiler.profiler_id)
                        }else {
                            notify(
                                `The answer external ID you provided was not found`, { type: 'error' }
                            );
                        }
                    } else {
                        notify(`The answer external ID you provided was not found`, { type: 'error' });
                    }
                })
                .catch((error) => {

                });
        }
    }
    const clearAnswerIdSearch = () => {
        setAnswerId('');
        setFilter(undefined)
    }
    const clearAnswerExternalIdSearch = () => {
        setAnswerExternalId('');
        setFilter(undefined)
    }

    return (
        <>
            <List filters={filters} filter={filter ? { id: filter } : null} bulkActionButtons={false} {...props}>
                <>
                    <div style={{backgroundColor: '#FAFAFA', paddingBottom: 20}}>
                        <TextField
                            style={{marginRight: 20, width: 300}}
                            id="filled-basic" label="Search by answer ID" variant="filled"
                            onChange={handleAnswerIdSearchChange}
                            onKeyUp={handleKeyUp}
                            value={answerId}
                            type="number"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {answerId && <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={clearAnswerIdSearch}
                                            edge="end"
                                        >
                                            <ClearIcon />
                                        </IconButton>}
                                    </InputAdornment>
                                )
                            }}
                        />

                        <TextField
                            style={{width: 300}}
                            id="filled-basic" label="Search by answer External ID" variant="filled"
                            onChange={handleAnswerExternalIdSearchChange}
                            onKeyUp={handleExternalIdKeyUp}
                            value={answerExternalId}
                            type="number"
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {answerExternalId && <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={clearAnswerExternalIdSearch}
                                      edge="end"
                                    >
                                      <ClearIcon />
                                    </IconButton>}
                                </InputAdornment>
                            )
                        }}
                        />
                    </div>

                    <Datagrid>
                        <RATextField source='id' />
                        <RATextField source='text' />
                        <RATextField source='name' />
                        <RATextField label='External ID' source='external_id' />
                        <RATextField label='Supplier' source='demand_source.name' />
                        <ShowButton label="Edit Mappings" style={{width: '200px'}} />
                    </Datagrid>
                </>


            </List>
        </>

    )
}

export default ProfilersList
