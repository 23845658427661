import React from "react";
import {DateField, Show, SimpleShowLayout, TextField, UrlField} from 'react-admin';
import { JsonField } from "react-admin-json-view";

const CommunityLeadsShow = (props: any) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source='lead_status_id' />
            <TextField source='sub_panel_id' />
            <TextField source='locale' />
            <TextField source='recruitment_platform' />
            <TextField source='recruitment_source' />
            <UrlField source="registration_link" />
            <TextField source='email' />
            <TextField source='first_name' />
            <TextField source='last_name' />
            <JsonField
                source="request"
                addLabel={true}
                jsonString={false} // Set to true if the value is a string, default: false
                reactJsonOptions={{
                    // Props passed to react-json-view
                    name: null,
                    collapsed: false,
                    enableClipboard: true,
                    displayDataTypes: false,
                }}
            />
            <DateField source="created_at" showTime={true} />
            <DateField source="updated_at" showTime={true} />
        </SimpleShowLayout>
    </Show>
);

export default CommunityLeadsShow
