import React, {useState} from 'react'
import {
    useDataProvider, useNotify
} from 'react-admin'
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Chip from '@mui/material/Chip';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {useHistory} from "react-router-dom";
import { PanelConfigKey, Value } from "./interfaces/panel-config-key";

const PanelConfigKeysCreate = (props: any) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const history = useHistory();

    const [newKey, setNewKey] = useState<PanelConfigKey>({
        key: '',
        label: '',
        type: 'text',
        panel_config_key_values: []
    });
    const [newValue, setNewValue] = useState<Value>({
        label: '',
        value: ''
    });

    const [valueDialogOpen, setValueDialogOpen] = useState(false);

    const handleChange = (key: string) => (event: any) => {
        const modifiedKey = JSON.parse(JSON.stringify(newKey));
        modifiedKey[key] = event.target.value;
        setNewKey(modifiedKey);
    };

    const handeChangeValue = (key: string) => (event: any) => {
        const modifiedValue = JSON.parse(JSON.stringify(newValue));
        modifiedValue[key] = event.target.value;
        setNewValue(modifiedValue);
    };

    const handleDelete = (value: Value) => () => {
        const modifiedKey = JSON.parse(JSON.stringify(newKey));
        modifiedKey.panel_config_key_values =
            modifiedKey.panel_config_key_values.filter((key:Value) => JSON.stringify(key) !== JSON.stringify(value));
        setNewKey(modifiedKey);
    }


    const handleSave =  () => {
        if (newKey.key === '' || newKey.label === '') {
            notify(`Please fill all values`, { type: 'error' });
            return;
        }
        if (['select', 'list'].indexOf(newKey.type) > -1 && newKey.panel_config_key_values.length < 2) {
            notify(`Please provide at least two selectable values`, { type: 'error' });
            return;
        }
        dataProvider.create('community_panel_config_keys', {
            data: {
                key: newKey.key,
                label: newKey.label,
                type: newKey.type
            },
        })
            .then(({data}) => {
                if (['select', 'list'].indexOf(data.type) > -1) {
                    const clonedData = JSON.parse(JSON.stringify(data));
                    const createValues = async () => {
                        await Promise.all(newKey.panel_config_key_values.map(async (value) => {
                            await dataProvider.create('community_panel_config_key_values', {
                                data: {
                                    panel_config_key_id: clonedData.id,
                                    label: value.label,
                                    value: value.value
                                },
                            })
                                .then(({data}) => {
                                    console.log(data)
                                })
                                .catch((error) => {

                                });
                        }));
                    }
                    createValues();

                    // newKey.values.forEach((value: string) => {
                    //     dataProvider.create('community_panel_config_key_values', {
                    //         data: {
                    //             panel_config_key_id: data.id,
                    //             label: value,
                    //             value: value
                    //         },
                    //     })
                    //         .then(({data}) => {
                    //             console.log(data)
                    //         })
                    //         .catch((error) => {
                    //
                    //         });
                    // })
                }

                notify(`New key created successfully`, { type: 'success' });
                history.push("/community_panel_config_keys");

            })
            .catch((error) => {

            });

    }

    const handleCloseDialog = () => {
        setNewValue({
            label: '',
            value: ''
        })
        setValueDialogOpen(false);
    };

    const saveNewValue = () => {
        const modifiedKey = JSON.parse(JSON.stringify(newKey));
        if(newValue.label === '' || newValue.value === ''){
            notify(`Please fill both fields before saving`, { type: 'error' });
            return;
        }
        modifiedKey.panel_config_key_values.push(newValue)
        setNewKey(modifiedKey);
        handleCloseDialog();
    }

    return (
            <Card sx={{ padding: '30px' }}>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"

                >
                    <TextField id="standard-basic" label="Key" variant="standard"
                               style={{ minWidth: 300, marginBottom: 30 }}
                               value={newKey.key}
                               onChange={handleChange('key')}

                    />
                    <TextField id="standard-basic" label="Label" variant="standard"
                               style={{ minWidth: 300, marginBottom: 30 }}
                               value={newKey.label}
                               onChange={handleChange('label')}
                    />
                    <ToggleButtonGroup
                        color="primary"
                        value={newKey.type}
                        style={{ marginTop: 20, marginBottom: 30 }}
                        exclusive
                        onChange={handleChange('type')}
                    >
                        <ToggleButton value="text">Text</ToggleButton>
                        <ToggleButton value="bool">Boolean</ToggleButton>
                        <ToggleButton value="select">Select</ToggleButton>
                        {/*<ToggleButton value="list">List</ToggleButton>*/}
                    </ToggleButtonGroup>

                    {['select', 'list'].indexOf(newKey.type) > -1 &&
                        <>
                          <Grid
                            container
                            direction="row"
                          >
                              {newKey.panel_config_key_values.map((value: Value)=>(
                                  <Chip label={`${value.value} - ${value.label}`} variant="outlined"
                                        style={{ marginRight: 5}}
                                        onClick={handleDelete(value)}
                                        onDelete={handleDelete} />

                              ))}
                          </Grid>
                          <Button
                            onClick={()=>{setValueDialogOpen(true)}}
                            style = {{marginBottom: 30}}
                          >Add new selectable value</Button>
                        </>
                    }



                    <Button variant="outlined" color="success"
                              size="large"
                            onClick={handleSave}
                    >
                        Save key
                    </Button>
                </Grid>

                <Dialog open={valueDialogOpen} onClose={handleCloseDialog}>
                    <DialogTitle>Add new key</DialogTitle>
                    <DialogContent>

                        <TextField id="standard-basic" label="Value" variant="standard"
                                   style={{ minWidth: 300}}
                                   value={newValue.value}
                                   onChange={handeChangeValue('value')}

                        />
                        <TextField id="standard-basic" label="Label" variant="standard"
                                   style={{ minWidth: 300}}
                                   value={newValue.label}
                                   onChange={handeChangeValue('label')}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} >Cancel</Button>
                        <Button onClick={saveNewValue}>Add keys</Button>
                    </DialogActions>
                </Dialog>
            </Card>



    )
}

export default PanelConfigKeysCreate
