import React from 'react'
import {
    List,
    Datagrid,
    TextField, ShowButton, TextInput, SelectInput
} from 'react-admin'

const filters = [
    <TextInput label="Name search" source="name" resettable alwaysOn style={{width: 300}} />,
    <TextInput label="Ext Project" source="ext_project_id" resettable alwaysOn  />,
    <TextInput label="Panel project" source="panel_project_id" resettable alwaysOn />,
    <SelectInput source="project_status" resettable alwaysOn choices={[
        {
            "id": 'open',
            "name": "Open"
        },
        {
            "id": 'closed',
            "name": "Closed"
        },
        {
            "id": 'archived',
            "name": "Archived"
        }
    ]} />,
    <SelectInput source="demand_source" resettable alwaysOn choices={[
        {
            "id": 'panel',
            "name": "Panel"
        },
        {
            "id": 'cint',
            "name": "CINT"
        },
        {
            "id": 'lucid',
            "name": "Lucid"
        },

    ]} />,

];
const OurSurveysProjectsList = (props: any) => {
    return (
        <List filters={filters} bulkActionButtons={false} {...props}>
            <Datagrid>
                <TextField source='name' />
                <TextField source='ext_project_id'/>
                <TextField source='panel_project_id'/>
                <TextField source='project_status'/>
                <TextField source='demand_source'/>
                <ShowButton />
            </Datagrid>
        </List>
    )
}

export default OurSurveysProjectsList
